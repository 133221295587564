<template>
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo">
      <span>
        <b-img
          :src="appLogoImageText"
          alt="logo"
          style="max-width: 130px"
        />
      </span>
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">You are not authorized! 🔐</h2>
        <p class="mb-2">You don’t have permission to access this page. Go Home!!</p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{ name: 'my-connect-dashboard', params: { prefix: prefix } }"
          >Back to Home</b-button
        >

        <imgNotAuthorizedDark v-if="layout === 'dark'" />
        <imgNotAuthorized v-else />
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton } from 'bootstrap-vue';
  import VuexyLogo from '@core/layouts/components/Logo.vue';
  import store from '@/store/index';
  import { getHomeRouteForLoggedInUser } from '@/auth/utils';
  import imgNotAuthorized from '@/assets/images/pages/error-troika.svg';
  import imgNotAuthorizedDark from '@/assets/images/pages/error-dark.svg';
  import { $themeConfig } from '@themeConfig';

  export default {
    components: {
      BLink,
      BImg,
      BButton,
      VuexyLogo,
      imgNotAuthorized,
      imgNotAuthorizedDark
    },
    data() {
      return {
        downImg: require('@/assets/images/pages/error-troika.svg'),
        prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix
      };
    },
    computed: {
      appLogoImageText() {
        const { appLogoImageText, appLogoImageTextDarkTheme } = $themeConfig.app;
        return localStorage.getItem('vuexy-skin') === 'dark'
          ? appLogoImageTextDarkTheme
          : appLogoImageText;
      },
      layout() {
        return store.state.appConfig.layout.skin;
      }
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/vue/pages/page-misc.scss';
</style>
